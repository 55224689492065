

































import { Component, Vue } from 'vue-property-decorator';
import KuspitAccountLinkingSuccessViewModel from '@/vue-app/view-models/components/contract-savings/kuspit-account-linking/kuspit-account-linking-success-view-model';

@Component({ name: 'KuspitAccountLinkingSuccess' })
export default class KuspitAccountLinkingSuccess extends Vue {
  kuspit_success_view_model = Vue.observable(
    new KuspitAccountLinkingSuccessViewModel(this),
  );
}
